import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nbr';
  isShown = 0;

  menu_labels = [
    'NBR Analytics',
    'NBR on Strava',
    'Our Presidents'
  ]

  displayedColumns: string[] = ['year', 'name', 'desc'];

  dataSource = [
    {year: 2022, name: 'Magne Gravås', desc: '"Bygger bruer"'},
    {year: 2021, name: 'Magne Gravås', desc: '"Bygger bruer"'},
    {year: 2020, name: 'Magne Gravås', desc: '"Bygger bruer"'},
    {year: 2019, name: 'Arne Johan Valen-Sendstad', desc: '"Alle skal bli sett"'},
    {year: 2018, name: 'Arne Johan Valen-Sendstad', desc: '"Fart i kroppen, oransje på toppen"'},
    {year: 2017, name: 'Thomas Grindstad', desc: '"Løfter oss frem og tar tida"'},
    {year: 2016, name: 'Dominique Bye-Ribaut', desc: '"Får hjertet til å hoppe"'},
    {year: 2015, name: 'Dominique Bye-Ribaut', desc: '"Spikerfeste må til"'},
    {year: 2014, name: 'Frode Nilssen', desc: '"Teknikk er tingen"'},
    {year: 2013, name: 'Frode Nilssen', desc: '"Riktig antrekk for enhver anledning"'},
    {year: 2012, name: 'Andreas Wessel', desc: '"Tramp takta med lua på snei"'},
    {year: 2011, name: 'Andreas Wessel', desc: '"Massasje er bestilt"'},
    {year: '...', name: '...'},
    {year: 2005, name: 'Eivind Lund', desc: '"Han ba ikke om det"'}
  ]

  constructor() {
    this.navigate(0);
}

  navigate(index): void {
    this.isShown = index;
  } 
}