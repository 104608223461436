<div fxLayout="row" fxLayoutAlign="left center" style="background-color: #FF5722; height: 60px">
  <button class="smallMediaMenuButton" [matMenuTriggerFor]="menu">Menu</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="navigate(0)">Analytics</button>
    <button mat-menu-item (click)="navigate(1)">NBR on Strava</button>
    <button mat-menu-item (click)="navigate(2)">Our Presidents</button>
  </mat-menu>
  <span style="margin-left: 20px; color: #FFF;">Nittedal Banquet Racers </span>
</div>

<mat-drawer-container>
  <mat-drawer #drawer mode="side" opened fxLayout="column" fxLayoutAlign="space-between center">
    <button class="menuButton" mat-raised-button (click)="navigate(0)">Analytics</button>
    <button class="menuButton" mat-raised-button (click)="navigate(1)">NBR on Strava</button>
    <button class="menuButton" mat-raised-button (click)="navigate(2)">Our Presidents</button>
  </mat-drawer>

  <div fxLayout="column" fxLayoutAlign="start start" style="margin-bottom: 70px !important;">
    <div fxLayout="row" fxLayoutAlign="left center" style="background-color: #000000; min-height: 60px; width: 100%;" >
      <span style="margin-left: 20px; color: #FFF;">{{ menu_labels[isShown] }} </span>
    </div>
    
    <div *ngIf="isShown == 0" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
        <iframe class="DS" src="https://datastudio.google.com/embed/reporting/15f238a1-d139-4ea7-998a-07ba6f4798e5/page/vR1lB"
          frameborder="0" style="border:0"></iframe>
      </div>
    </div>

    <div *ngIf="isShown == 1" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
      <div class="strava-container" fxLayout="column" fxLayoutAlign="center center">
        <iframe class="strava" allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/197177/latest-rides/4d02e35f66b228fd5219bc5e51be685ca0777d36?show_rides=false'></iframe>
        <iframe class="strava" allowtransparency frameborder='0' height='454' scrolling='no' src='https://www.strava.com/clubs/197177/latest-rides/4d02e35f66b228fd5219bc5e51be685ca0777d36?show_rides=true'></iframe>
      </div>
    </div>
  
    <div *ngIf="isShown == 2" fxLayout="row" fxLayoutAlign="start start" fxFlexFill style="padding: 20px;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef> Year </th>
          <td mat-cell *matCellDef="let element"> {{element.year}} </td>
        </ng-container>
      
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef> Achievements </th>
          <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
  </div>

</mat-drawer-container>